import React, { Fragment } from 'react';
import { withRouter} from 'react-router-dom'
import { Grid, Button, Tooltip } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FolderLevelFilesModal from './FolderLevelFilesModal';
import componentPermissionHoc from '../../../hoc/componentPermissionHoc'
import { checkPermission } from '../../../utils';
const DataRoomNav = ({match, selectedItems, onDeleteDMData, onBulkDMMove, toggleModalFn, toggleUploadModalFn, dataRoom, analyticStats, shareRecord, onDownloadData, changeDMFilesStatus,setselectedAsset, onSendViaEmail}) => {
  return(
    <Fragment>
      <div className="data-room-nav">
        <Grid container alignItems="center" spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} md={12}>
            <ul className="list-inline flex-centered data-room-upload-option">
              { checkPermission('records','data_room', 'DN') || (dataRoom.folder_collaboration && (dataRoom.folder_collaboration.can_edit === 1 || dataRoom.folder_collaboration.can_edit === 0)) ?
                <li className="list-inline-item">
                  <Button onClick={() => onDownloadData('single')} color="primary" size="small" variant="contained" endIcon={<CloudDownloadIcon />}>Download</Button>
                </li>
                :null
              }
              { match.params.type === 'technical' && checkPermission('records','data_room', 'PUB_REJ') && analyticStats.general_info && analyticStats.general_info.unpublished_files ?
              <>
                <li className="list-inline-item">
                  <FolderLevelFilesModal changeDMFilesStatus={changeDMFilesStatus} analyticStats={analyticStats} type="unpublished" />
                </li>
                <li>
                  <Button onClick={() => {changeDMFilesStatus({status:3,type:'file', uuid:[], to_consider:'unpublished', folder_uuid:[match.params.uuid]})}} size="small" variant="contained"  color="primary">Publish All - {analyticStats.general_info.unpublished_files}</Button>
                </li>
              </>:null
              }
              { match.params.type === 'technical' && checkPermission('records','data_room', 'RENAME') && analyticStats.general_info && analyticStats.general_info.to_be_renamed ?
                <li className="list-inline-item">
                  <FolderLevelFilesModal changeDMFilesStatus={changeDMFilesStatus} analyticStats={analyticStats} type="rename" />
                </li>:null
              }
              { selectedItems.ids.length ?
                 <Fragment>
                  { checkPermission('records','data_room', 'DN') || (dataRoom.folder_collaboration && (dataRoom.folder_collaboration.can_edit === 1 || dataRoom.folder_collaboration.can_edit === 0)) ?
                    <li className="list-inline-item">
                      <Button onClick={() => onDownloadData('multi')} color="primary" size="small" variant="outlined">Download ({selectedItems.ids.length})</Button>
                    </li>:null
                  }
                  { checkPermission('records','data_room', 'MOV') ?
                    <li className="list-inline-item">
                      <Button onClick={() => {setselectedAsset(); setTimeout(() => {onBulkDMMove()}, 300)}}  color="primary" size="small" variant="outlined">Move ({selectedItems.ids.length})</Button>
                    </li>
                    :null
                  }
                  { checkPermission('records','data_room', 'D') ?
                    <li className="list-inline-item">
                      <Button onClick={() => onDeleteDMData()} color="primary" size="small" variant="outlined">Trash ({selectedItems.ids.length})</Button>
                    </li>:null
                  }
                    { checkPermission('records','data_room', 'SNDEML') ?
                    <li className="list-inline-item">
                      <Button onClick={() => onSendViaEmail(selectedItems)} color="primary" size="small" variant="outlined">Send via Email ({selectedItems.ids.length})</Button>
                    </li>:
                    null}

                 </Fragment>:null
              }
            </ul>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  )
}
export default withRouter(DataRoomNav);
